export const formatRequestedDate = (dateString, includeTime = false, includeCW = true, includeYear = false, includeWeekday = false) => {
    const date = new Date(dateString);
    const weekday = new Intl.DateTimeFormat('en-US', { weekday: 'long' }).format(date).slice(0, 3);
    const day = date.getDate();
    const month = new Intl.DateTimeFormat('en-US', { month: 'short' }).format(date).slice(0, 3);
    const year = date.getFullYear();
    const weekNumber = getWeekNumber(date);
    const time = includeTime ? date.toLocaleTimeString('en-US', { hour: 'numeric', minute: 'numeric' }) : '';
    return `${includeCW ? 'CW' + weekNumber + ',' : ''} ${includeWeekday ? weekday + ',' : ''} ${day} ${month} ${includeYear ? year : ''} ${includeTime ? time : ''}`;
};

const getWeekNumber = (date) => {
    const onejan = new Date(date.getFullYear(), 0, 1);
    const weekNumber = Math.ceil((((date - onejan) / 86400000) + onejan.getDay() + 1) / 7);
    return weekNumber;
};
