/*const data = [
    { id: 1, date_time: "2020-12-28", calendar_week: 53, harvestable_fruits: 14600.000000 },
    { id: 2, date_time: "2021-01-04", calendar_week: 1, harvestable_fruits: 17000.000000 },
    { id: 3, date_time: "2021-01-11", calendar_week: 2, harvestable_fruits: 15700.000000 },
    { id: 4, date_time: "2021-01-18", calendar_week: 3, harvestable_fruits: 13000.000000 },
    { id: 5, date_time: "2021-01-25", calendar_week: 4, harvestable_fruits: 14000.000000 },
    { id: 6, date_time: "2021-02-01", calendar_week: 5, harvestable_fruits: 14500.000000 },
    { id: 7, date_time: "2021-02-08", calendar_week: 6, harvestable_fruits: 14050.112305 }
];

export default data;*/
const data = {
    "date_time": {
        "0": 1609113600000,
        "1": 1609718400000,
        "2": 1610323200000,
        "3": 1610928000000,
        "4": 1611532800000,
        "5": 1612137600000,
        "6": 1612742400000
    },
    "calendar_week": {
        "0": 53,
        "1": 1,
        "2": 2,
        "3": 3,
        "4": 4,
        "5": 5,
        "6": 6
    },
    "harvestable_fruits": {
        "0": 14600,
        "1": 17000,
        "2": 15700,
        "3": 13000,
        "4": 14000,
        "5": 14500,
        "6": 14050.1123046875
    }
};

export default data;

