import * as React from 'react';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import dayjs from 'dayjs';
import './CustomDateField.css'
import updateLocale from "dayjs/plugin/updateLocale";

const theme = createTheme({
    components: {
        MuiTypography: {
            styleOverrides: {
                caption: {
                    '&.MuiDayCalendar-weekNumberLabel': {
                        color: '#02C39A',
                    },
                },
            },
        },
    },
});

export default function CustomDateField({ value, onChange }) {

    dayjs.extend(updateLocale);

    dayjs.updateLocale("en", {
        weekStart: 1
    });

    const handleDateChange = (date) => {
        const jsDate = date ? date.toDate() : null;
        if (jsDate instanceof Date && !isNaN(jsDate.getTime())) {
            onChange(jsDate);
        } else {
            console.error("Invalid Date:", jsDate);
        }
    };

    const formattedValue = value ? dayjs(value) : null;
    const makeDisableDate = (date) => {
        return date.isBefore(dayjs(), 'day');
    };

    return (
        <ThemeProvider theme={theme}>
            <LocalizationProvider dateAdapter={AdapterDayjs}
                localeText={{
                    calendarWeekNumberHeaderText: 'CW',
                    calendarWeekNumberText: (weekNumber) => `${weekNumber}.`,
                }}>

                <DatePicker
                    displayWeekNumber
                    className='date'
                    value={formattedValue}
                    onChange={handleDateChange}
                    shouldDisableDate={makeDisableDate}
                    format={`CW${formattedValue.week()}, ddd, DD MMM `}

                    slotProps={{
                        textField: {
                            className: 'myDatePicker',
                            InputProps: {
                                classes: {
                                    notchedOutline: 'MuiOutlinedInput-notchedOutline',
                                },
                            },
                        },
                        popper: {
                            sx: {
                                ".MuiPaper-root": {
                                    border: "2px solid #B5B5B5",
                                    borderRadius: "7px",

                                    "& .MuiPickersDay-root.Mui-selected": {
                                        backgroundColor: '#05668D',
                                    },

                                    "& .MuiPickersDay-root:hover": {
                                        backgroundColor: 'rgba(0, 0, 0, 0.1)', // Slight Gray 
                                        cursor: 'pointer',
                                    },
                                    '& .MuiDayCalendar-weekNumber': {
                                        color: '#02C39A',
                                    },
                                },
                            },
                        },

                    }}
                />
            </LocalizationProvider>
        </ThemeProvider>
    );
}
