import React, { useState, useEffect } from 'react';
import { Drawer, List, ListItem, ListItemIcon, ListItemText } from '@mui/material';
import '@aws-amplify/ui-react/styles.css';
import { ReportIcon } from './icons/ReportIcon.jsx';
import { HarvestRequestIcon } from './icons/HarvestRequestIcon.jsx';
import Report from './Report/Report.js';
import HarvestRequest from './Harvest-Request/HarvestRequest.js';
import data from './data.js';
import NavigationBar from './Reusablecomponents/NavigationBar.js';
import { Authenticator, Image, View, useTheme } from '@aws-amplify/ui-react';
import './App.css';
import harvestLogo from './Harvest_Logo.png';
import { I18n } from 'aws-amplify';
import CurrentDate from './Harvest-Request/CurrentDate';
I18n.putVocabulariesForLanguage('en', {
  'Sign in': 'Login',
});

const formFields = {
  signIn: {
    username: {
      placeholder: 'Email',
      isRequired: true,
      label: ''
    },
    password: {
      placeholder: 'Password',
      isRequired: true,
      label: ''
    },
  },
};

const components = {
  SignIn: {
    Header() {
      const { tokens } = useTheme();

      return (
        <View textAlign="center" padding={tokens.space.large}>
          <Image
            alt="Harvest logo"
            src={harvestLogo}
          />
        </View>
      );
    },
  }
};

function App() {
  const [selected, setSelected] = useState(() => localStorage.getItem('selected') || 'Report');
  const [harvestRequest, setHarvestRequest] = useState();
  const [isDrawerOpen, setIsDrawerOpen] = useState(true);
  
  useEffect(() => {
    localStorage.setItem('selected', selected);
  }, [selected]);

  const handleListItemClick = (event, name) => {
    setSelected(name);
  };

  const toggleDrawer = () => {
    setIsDrawerOpen(!isDrawerOpen);
  };

  const menuItems = [
    { name: 'Report', icon: ReportIcon },
    { name: 'Harvest Request', icon: HarvestRequestIcon },
  ];

  return (
    <div className="App">
      <NavigationBar toggleDrawer={toggleDrawer} harvestRequest={harvestRequest}
        setHarvestRequest={setHarvestRequest} />
      <div style={{ display: 'flex' }}>
        <Drawer
          sx={{
            width: 240,
            flexShrink: 0,
            '& .MuiDrawer-paper': {
              width: 240,
              marginTop: 8,
              BorderRight: 0,
            },
          }}
          variant="persistent"
          anchor="left"
          open={isDrawerOpen}
        >
          <List>
            {menuItems.map((item) => (
              <ListItem
                button
                key={item.name}
                selected={selected === item.name}
                onClick={(event) => handleListItemClick(event, item.name)}
              >
                <ListItemIcon>
                  <item.icon
                    harvestRequest={harvestRequest}
                    setHarvestRequest={setHarvestRequest}
                    color={selected === item.name ? '#02C39A' : '#717171'}
                  />
                </ListItemIcon>
                <ListItemText primary={item.name + '  •'} />
              </ListItem>
            ))}
          </List>
          <div><CurrentDate /></div>
        </Drawer>

        <div className="content-container">
          {selected === 'Report' && <Report data={data} />}
          {selected === 'Harvest Request' && <HarvestRequest setHarvestRequest={setHarvestRequest} />}
          
        </div>
      </div>
    </div>
  );
}

export default () => (
  <Authenticator hideDefault={true} hideSignUp={true} formFields={formFields} components={components}>
    <App />
  </Authenticator>
);
