import tokenManager from './TokenManager';
import { Auth } from 'aws-amplify';


export const fetchOrganizationId = async () => {
    const token = await tokenManager.getToken();
    if (!token) {
        throw new Error('Failed to get the token');
    }
    const userId = await tokenManager.getUserID();
    const getUserResponse = await fetch(`${process.env.REACT_APP_API_URL}/user/${userId}`, {
        method: 'GET',
        headers: {
            Authorization: `Bearer ${token}`,
            'Accept': 'application/json',
        },
    }).then(r => r.json()).catch(error => {
        throw new Error(`Error fetching organization ID: ${error}`)
    });
    return getUserResponse['organization_uuid']
}

export const fetchUserDetails = async () => {
    const token = await tokenManager.getToken();
    if (!token) {
        throw new Error('Failed to get the token');
    }
    const userId = await tokenManager.getUserID();
    const getUserData = await fetch(`${process.env.REACT_APP_API_URL}/user/${userId}`, {
        method: 'GET',
        headers: {
            Authorization: `Bearer ${token}`,
            'Accept': 'application/json',
        },
    }).then(r => r.json()).catch(error => {
        throw new Error(`Error fetching organization ID: ${error}`)
    });
    return getUserData
}

export const fetchLoginData = async () => {
    try {
        const userInfo = await Auth.currentAuthenticatedUser();
        return userInfo.attributes;
    } catch (error) {
        console.error('Error fetching user information:', error);
        throw new Error('Failed to fetch user information');
    }
}

export const fetchSites = async (organizationId) => {
    const token = await tokenManager.getToken();
    if (!token) {
        throw new Error('Failed to get the token');
    }

    return await fetch(`${process.env.REACT_APP_API_URL}/organization/${organizationId}/sites`, {
        method: 'GET',
        headers: {
            Authorization: `Bearer ${token}`,
            'Accept': 'application/json',
        },
    }).then(r => r.json()).catch(error => {
        throw new Error(`Error fetching sites: ${error}`)
    });
}

export const fetchGreenhouses = async (organizationId, siteId) => {
    const token = await tokenManager.getToken();
    if (!token) {
        throw new Error('Failed to get the token');
    }

    return await fetch(`${process.env.REACT_APP_API_URL}/organization/${organizationId}/site/${siteId}/greenhouses`, {
        method: 'GET',
        headers: {
            Authorization: `Bearer ${token}`,
            'Accept': 'application/json',
        },
    }).then(r => r.json()).catch(error => {
        throw new Error(`Error fetching greenhouses: ${error}`)
    });
}

export const fetchCompartments = async (organizationId, siteId, greenhouseId) => {
    const token = await tokenManager.getToken();
    if (!token) {
        throw new Error('Failed to get the token');
    }

    return await fetch(`${process.env.REACT_APP_API_URL}/organization/${organizationId}/site/${siteId}/greenhouse/${greenhouseId}/compartments`, {
        method: 'GET',
        headers: {
            Authorization: `Bearer ${token}`,
            'Accept': 'application/json',
        },
    }).then(r => r.json()).catch(error => {
        throw new Error(`Error fetching compartments: ${error}`)
    });
}

export const fetchCrops = async (organizationId, siteId, greenhouseId, compartmentId) => {
    const token = await tokenManager.getToken();
    if (!token) {
        throw new Error('Failed to get the token');
    }

    return await fetch(`${process.env.REACT_APP_API_URL}/organization/${organizationId}/site/${siteId}/greenhouse/${greenhouseId}/compartment/${compartmentId}/cultivation-zones`, {
        method: 'GET',
        headers: {
            Authorization: `Bearer ${token}`,
            'Accept': 'application/json',
        },
    }).then(r => r.json()).catch(error => {
        throw new Error(`Error fetching crops: ${error}`)
    });
}

export const fetchPrediction = async (cropId, currentHarvestableFruits, futureHarvestableFruits, light,
    t_Day,
    t_Night,
    co2) => {
    const token = await tokenManager.getToken();
    if (!token) {
        throw new Error('Failed to get the token');
    }
    const response = await fetch(
        `${process.env.REACT_APP_API_URL}/cultivation-zone/${cropId}/prediction?harvest-volume-current=${currentHarvestableFruits}&harvest-volume-future=${futureHarvestableFruits}&light=${light}&temp-day=${t_Day}&temp-night=${t_Night}&co2=${co2}`,
        {
            method: 'GET',
            headers: {
                Authorization: `Bearer ${token}`,
                'Accept': 'application/json',
            },
        }
    );

    if (!response.ok) {
        throw new Error('Failed to get yield prediction');
    }
    return await response.json();
}

export const fetchHistoryEvents = async (cropId) => {
    const token = await tokenManager.getToken();
    if (!token) {
        throw new Error('Failed to get the token');
    }

    const now = new Date();
    const monthAgo = new Date();
    monthAgo.setMonth(now.getMonth() - 2);
    const response = await fetch(`${process.env.REACT_APP_API_URL}/cultivation-zone/${cropId}/harvest-events?start-date=${monthAgo.toISOString()}&end-date=${now.toISOString()}`, {
        method: 'GET',
        headers: {
            Authorization: `Bearer ${token}`,
            'Accept': 'application/json',
        },
    });

    if (!response.ok) {
        throw new Error(`Network response was not ok (Status: ${response.status})`);
    }
    return await response.json();
}

export const fetchSensorData = async (cropId) => {
    try {
        const token = await tokenManager.getToken();
        if (!token) {
            throw new Error('Failed to get the token');
        }
        const now = new Date();
        const monthAgo = new Date();
        monthAgo.setMonth(now.getMonth() - 2);

        const response = await fetch(`${process.env.REACT_APP_API_URL}/cultivation-zone/${cropId}/climate-data?start-date=${monthAgo.toISOString()}&end-date=${now.toISOString()}`, {
            method: 'GET',
            headers: {
                Authorization: `Bearer ${token}`,
                'Accept': 'application/json',
            },
        });
        if (response.status === 400 || response.status === 404) {
            return null;
        }

        if (!response.ok) {
            throw new Error('Failed to fetch sensor data');
        }

        return await response.json();
    } catch (error) {
        console.log(error);
        return null;
    }
};
export const fetchAllCrops = async () => {
    const token = await tokenManager.getToken();
    if (!token) {
        throw new Error('Failed to get the token');
    }

    return await fetch(`${process.env.REACT_APP_API_URL}/crops`, {
        method: 'GET',
        headers: {
            Authorization: `Bearer ${token}`,
            'Accept': 'application/json',
        },
    }).then(r => r.json()).catch(error => {
        throw new Error(`Error fetching crops: ${error}`)
    });
}

export const fetchHarvestRequests = async () => {
    const token = await tokenManager.getToken();
    if (!token) {
        throw new Error('Failed to get the token');
    }

    try {
        const organizationUuid = await fetchOrganizationId();
        const token = await tokenManager.getToken();
        if (!token) {
            throw new Error('Failed to get the token');
        }

        const response = await fetch(`${process.env.REACT_APP_API_URL}/organization/${organizationUuid}/harvest-requests`, {
            method: 'GET',
            headers: {
                Authorization: `Bearer ${token}`,
                'Accept': 'application/json',
            },
        });
        if (!response.ok) {
            throw new Error('Failed to fetch harvest requests');
        }

        const data = await response.json();
        return data;
    } catch (error) {
        console.error('Error fetching harvest requests:', error);
        throw new Error('Failed to fetch harvest requests');
    }
};

export const createHarvestRequest = async (requestData) => {
    try {
        const organizationUuid = await fetchOrganizationId();
        const token = await tokenManager.getToken();
        if (!token) {
            throw new Error('Failed to get the token');
        }

        const response = await fetch(`${process.env.REACT_APP_API_URL}/organization/${organizationUuid}/harvest-requests`, {
            method: 'POST',
            headers: {
                Authorization: `Bearer ${token}`,
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(requestData),
        });

        if (!response.ok) {
            throw new Error('Failed to create harvest request');
        }

        const data = await response.json();
        return data;
    } catch (error) {
        console.error('Error creating harvest request:', error);
        throw new Error('Failed to create harvest request');
    }
};

export const updateHarvestRequest = async (requestId, updatedData) => {
    try {
        const organizationUuid = await fetchOrganizationId();
        const token = await tokenManager.getToken();
        if (!token) {
            throw new Error('Failed to get the token');
        }

        const response = await fetch(`${process.env.REACT_APP_API_URL}/organization/${organizationUuid}/harvest-requests/${requestId}`, {
            method: 'PUT',
            headers: {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(updatedData),
        });

        if (!response.ok) {
            throw new Error('Failed to update harvest request');
        }

        const data = await response.json();
        return data;
    } catch (error) {
        console.error('Error updating harvest request:', error);
        throw new Error('Failed to update harvest request');
    }
};

