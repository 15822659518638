import React, { useEffect, useState } from "react";
import { fetchCompartments, fetchCrops, fetchGreenhouses, fetchOrganizationId, fetchSites } from "../api";
import Dropdown from "./Dropdown";

const CropSelector = ({ setCropId }) => {
    const [organizationId, setOrganizationId] = useState('');

    const [site, setSite] = useState('');
    const [siteList, setSiteList] = useState([]);

    const [greenhouse, setGreenhouse] = useState('');
    const [greenhouseList, setGreenhouseList] = useState([]);

    const [compartment, setCompartment] = useState('');
    const [compartmentList, setCompartmentList] = useState([]);

    const [crop, setCrop] = useState('');
    const [cropList, setCropList] = useState([]);

    const handleSiteChange = (event) => {
        setSite(() => event.target.value);
    };

    const handleGreenhouseChange = (event) => {
        setGreenhouse(event.target.value);
    };

    const handleCompartmentChange = (event) => {
        setCompartment(event.target.value);
    };

    const handleCropChange = (event) => {
        cropList.filter(c => event.target.value === c.name).forEach(c => {
            setCrop(c);
            setCropId(c.id)
        })
    };

    useEffect(() => {
        const fetchData = async () => {
            const organizationId = await fetchOrganizationId();
            setOrganizationId(organizationId);
        }
        fetchData().catch(err => console.error(err));
    }, [])

    useEffect(() => {
        if (!organizationId) {
            return;
        }
        const fetchData = async () => {
            const sites = await fetchSites(organizationId);
            setSiteList([...sites]);
            if (sites.length === 0) {
                return;
            }
            setSite(`${sites[0].name}`)
        }
        fetchData().catch(err => console.error(err));
    }, [organizationId])

    useEffect(() => {
        if (!organizationId) {
            return;
        }
        if (!site || !siteList) {
            return;
        }
        const siteData = siteList.filter(s => s.name === site)[0];
        if (!siteData) {
            return;
        }
        const siteId = siteData.id;

        const fetchData = async () => {
            const greenhouses = await fetchGreenhouses(organizationId, siteId);
            setGreenhouseList([...greenhouses]);
            if (greenhouses.length === 0) {
                return;
            }
            setGreenhouse(greenhouses[0].name)
        }
        fetchData().catch(err => console.error(err));
    }, [organizationId, site])

    useEffect(() => {
        if (!organizationId) {
            return;
        }
        const siteData = siteList.filter(s => s.name === site)[0];
        if (!siteData) {
            return;
        }
        const siteId = siteData.id;

        const greenhouseData = greenhouseList.filter(g => g.name === greenhouse)[0];
        if (!greenhouseData) {
            return;
        }
        const greenhouseId = greenhouseData.id;

        const fetchData = async () => {
            const compartments = await fetchCompartments(organizationId, siteId, greenhouseId);
            setCompartmentList(compartments);
            if (compartments.length === 0) {
                return;
            }
            setCompartment(compartments[0].name)
        }

        fetchData().catch(err => console.error(err));
    }, [organizationId, site, siteList, greenhouse, greenhouseList])

    useEffect(() => {

        if (!organizationId) {
            return;
        }
        const siteData = siteList.filter(s => s.name === site)[0];
        if (!siteData) {
            return;
        }
        const siteId = siteData.id;

        const greenhouseData = greenhouseList.filter(g => g.name === greenhouse)[0];
        if (!greenhouseData) {
            return;
        }
        const greenhouseId = greenhouseData.id;

        const compartmentData = compartmentList.filter(c => c.name === compartment)[0];
        if (!compartmentData) {
            return;
        }
        const compartmentId = compartmentData.id;

        const fetchData = async () => {
            const crops = await fetchCrops(organizationId, siteId, greenhouseId, compartmentId);
            setCropList(crops);
            if (crops.length === 0) {
                return;
            }
            setCrop(crops[0])
            setCropId(crops[0].id);
        }

        fetchData().catch(err => console.error(err));
    }, [organizationId, site, siteList, greenhouse, greenhouseList, compartment, compartmentList, setCropId])


    return (
        <div style={{ display: 'flex', marginTop: '10px' }}>
            <Dropdown name="Site" values={siteList.map(s => `${s.name}`)} selectedValue={`${site}`} showColon={true}
                handleChange={handleSiteChange} />
            <Dropdown name="Greenhouse" values={greenhouseList.map(g => g.name)} selectedValue={greenhouse} showColon={true}
                handleChange={handleGreenhouseChange} />
            <Dropdown name="Compartment" values={compartmentList.map(c => c.name)} selectedValue={compartment} showColon={true}
                handleChange={handleCompartmentChange} />
            <Dropdown name="Fruits" values={cropList.map(crop => crop ? crop.name : '')} selectedValue={crop ? crop.name : ''} showColon={true}
                handleChange={handleCropChange} />
        </div>
    )
};

export default CropSelector;