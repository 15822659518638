import React, { useState } from 'react';
import { FormControl, InputLabel, MenuItem, Select } from '@mui/material';

function Dropdown(props) {
    const { name, values, selectedValue, handleChange, showColon } = props;

    return (
        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
            <InputLabel id={`${name}-label`} sx={{ color: '#000', fontWeight: '800', marginRight: '8px', fontFamily: 'Red Hat Display' }}>
                {name}{showColon ? ':' : ''}
            </InputLabel>
            <FormControl variant="standard" >
                <Select
                    labelId={`${name}-label`}
                    id={name}
                    value={selectedValue}
                    onChange={handleChange}
                    disableUnderline
                    sx={{ '& .MuiSelect-select' : { display: 'flex', alignItems: 'center', }, '& .MuiSelect-root': { border: 'none' }, }}
                >
                    {values.map((value) => (
                        <MenuItem key={value} value={value}>
                            {value}
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>
        </div >
    );
}

export default Dropdown;
